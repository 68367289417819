var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "edit-table" },
    [
      _c(
        "div",
        { staticClass: "padding_50" },
        [
          !_vm.disabled
            ? _c("vxe-toolbar", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "vxe-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addTable()
                                },
                              },
                            },
                            [_vm._v("添加行")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2279914514
                ),
              })
            : _vm._e(),
          _c(
            "vxe-table",
            {
              ref: "fineTable",
              attrs: {
                "show-overflow": "",
                data: _vm.subjectList,
                height: "300px",
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { width: "50", field: "rowIndex", title: "序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var rowIndex = ref.rowIndex
                      return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  width: "100",
                  field: "productRange",
                  title: "产品维度",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var rowIndex = ref.rowIndex
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              placeholder: "请选择",
                              disabled: _vm.disabled,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.handleSelect(row, rowIndex)
                              },
                            },
                            model: {
                              value: row.productRange,
                              callback: function ($$v) {
                                _vm.$set(row, "productRange", $$v)
                              },
                              expression: "row.productRange",
                            },
                          },
                          _vm._l(_vm.productRange, function (item) {
                            return _c("el-option", {
                              key: item.dictCode,
                              attrs: {
                                label: item.dictValue,
                                value: item.dictCode,
                              },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "200",
                  field: "productLevelName",
                  title: "产品系列",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var rowIndex = ref.rowIndex
                      return [
                        _c(
                          "div",
                          { staticClass: "cost-select" },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  "filter-method": _vm.getProLevelList,
                                  clearable: "",
                                  disabled:
                                    row.productLevelIsEnable || _vm.disabled,
                                },
                                on: {
                                  "visible-change": function ($event) {
                                    return _vm.getProLevelList("")
                                  },
                                  change: function ($event) {
                                    return _vm.productLevelNameChange(
                                      $event,
                                      rowIndex
                                    )
                                  },
                                },
                                model: {
                                  value: row.productLevelCode,
                                  callback: function ($$v) {
                                    _vm.$set(row, "productLevelCode", $$v)
                                  },
                                  expression: "row.productLevelCode",
                                },
                              },
                              _vm._l(_vm.proLevelList, function (item) {
                                return _c("el-option", {
                                  key: item.value,
                                  attrs: {
                                    label: item.label,
                                    value: item.value,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "200",
                  field: "productName",
                  title: "产品名称",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var rowIndex = ref.rowIndex
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              filterable: "",
                              "filter-method": _vm.getRoleSelectListArr,
                              clearable: "",
                              disabled: row.productIsEnable || _vm.disabled,
                            },
                            on: {
                              "visible-change": function ($event) {
                                return _vm.getRoleSelectListArr("")
                              },
                              change: function ($event) {
                                return _vm.productNameChange($event, rowIndex)
                              },
                            },
                            model: {
                              value: row.productCode,
                              callback: function ($$v) {
                                _vm.$set(row, "productCode", $$v)
                              },
                              expression: "row.productCode",
                            },
                          },
                          _vm._l(_vm.proList, function (item) {
                            return _c("el-option", {
                              key: item.value,
                              attrs: { label: item.label, value: item.value },
                            })
                          }),
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: { width: "100", field: "score", title: "产品凤香值/瓶" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var rowIndex = ref.rowIndex
                      return [
                        _c("el-input", {
                          attrs: {
                            type: "number",
                            placeholder: "请输入",
                            disabled: _vm.disabled,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.scoreInput($event, rowIndex)
                            },
                          },
                          model: {
                            value: row.score,
                            callback: function ($$v) {
                              _vm.$set(row, "score", $$v)
                            },
                            expression: "row.score",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  width: "100",
                  field: "scanRequired",
                  title: "扫码次数",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var rowIndex = ref.rowIndex
                      return [
                        _c("el-input", {
                          attrs: {
                            type: "number",
                            placeholder: "请输入",
                            disabled: _vm.disabled,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.handleInput($event, rowIndex, 0)
                            },
                          },
                          model: {
                            value: row.scanRequired,
                            callback: function ($$v) {
                              _vm.$set(row, "scanRequired", $$v)
                            },
                            expression: "row.scanRequired",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  width: "100",
                  field: "rewardGranted",
                  title: "获奖次数",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      var rowIndex = ref.rowIndex
                      return [
                        _c("el-input", {
                          attrs: {
                            type: "number",
                            placeholder: "请输入",
                            disabled: _vm.disabled,
                          },
                          on: {
                            input: function ($event) {
                              return _vm.handleInput($event, rowIndex, 1)
                            },
                          },
                          model: {
                            value: row.rewardGranted,
                            callback: function ($$v) {
                              _vm.$set(row, "rewardGranted", $$v)
                            },
                            expression: "row.rewardGranted",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              !_vm.disabled
                ? _c("vxe-column", {
                    attrs: { title: "操作", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            var rowIndex = ref.rowIndex
                            return [
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deletRow(row, rowIndex)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      3921525946
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "form_title" }, [_vm._v("排除产品")]),
      _c(
        "div",
        { staticClass: "padding_50" },
        [
          !_vm.disabled
            ? _c("vxe-toolbar", {
                scopedSlots: _vm._u(
                  [
                    {
                      key: "buttons",
                      fn: function () {
                        return [
                          _c(
                            "vxe-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.addTable1()
                                },
                              },
                            },
                            [_vm._v("添加行")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4006358051
                ),
              })
            : _vm._e(),
          _c(
            "vxe-table",
            {
              ref: "fineTable",
              attrs: {
                "show-overflow": "",
                data: _vm.subjectList1,
                height: "300px",
              },
            },
            [
              _c("vxe-table-column", {
                attrs: { "min-width": "100", field: "rowIndex", title: "序号" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var rowIndex = ref.rowIndex
                      return [_vm._v(" " + _vm._s(rowIndex + 1) + " ")]
                    },
                  },
                ]),
              }),
              _c("vxe-table-column", {
                attrs: {
                  "min-width": "100",
                  field: "materialName",
                  title: "产品名称",
                },
              }),
              !_vm.disabled
                ? _c("vxe-column", {
                    attrs: { title: "操作", width: "80" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var row = ref.row
                            return [
                              [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      color: "red",
                                      cursor: "pointer",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deletRow1(row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2147257131
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }