import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import { log } from 'mathjs';
import addForm from './components/addForm.vue';

import scoreRatio from './components/scoreRatio.vue';
import BusinessOrganization from './components/business_organization.vue';
import ExcludeProductList from './ExcludeProductList.vue';
import ProductLevelNameList from './ProductLevelNameList.vue';

formCreate.component('addForm', addForm);

formCreate.component('scoreRatio', scoreRatio);
formCreate.component('BusinessOrganization', BusinessOrganization);
formCreate.component('ExcludeProductList', ExcludeProductList);
formCreate.component('ProductLevelNameList', ProductLevelNameList);
export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'product_score_form',
    };
  },
  async created() {
    await this.getFormRule('product_score_form');

    const productLevelName = this.getRule('productLevelName');
    productLevelName.props = {
      ...productLevelName.props,
      params: {
        functionCode: 'select_level_list',
      },
      getValue: (field) => this.getFieldValue(field),
      title: productLevelName.title,
    };
    const excludeProductList = this.getRule('excludeProductList');
    productLevelName.on.change = (e) => {
      if (e && e.length > 0) {
        excludeProductList.props = {
          ...excludeProductList.props,
          catena: e[0].productLevelCode,
        };
        this.setValue({
          productLevelName: e[0].productLevelName,
          productLevelCode: e[0].productLevelCode,
        });
      } else {
        excludeProductList.props = {
          ...excludeProductList.props,
          catena: null,
        };
        this.setValue({
          productLevelName: null,
          productLevelCode: null,
        });
      }
    };
    // 先隐藏系列没选择时候的字段
    this.hiddenFields(true, ['productCode', 'productLevelName', 'excludeProductList']);
  },
  methods: {

    // 设置规则
    setRule(item) {
      console.log('item', item);
      const that = this;
      if (item.field === 'productRange') {
        item.on = {
          ...item.on,
          change(val) {
            console.log('🚀 ~ change ~ val:', val);
            if (val === 'level') {
              that.hiddenFields(true, ['productCode']);
              that.hiddenFields(false, ['productLevelName', 'excludeProductList']);
            } else {
              that.hiddenFields(true, ['productLevelName', 'excludeProductList']);
              that.hiddenFields(false, ['productCode']);
            }
          },
        };
      } else if (item.field === 'orgName') {
        item.props = {
          ...item.props,
          params: {
            functionCode: 'business_organization_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: item.title,
          autosize: { minRows: 1, maxRows: 6 },
        };
        item.on = {
          ...item.on,
          change: (e) => {
            console.log('🚀 ~ setRule ~ e:', e);
            const arr = [...e];
            const orgVos = [];
            arr.forEach((m) => {
              orgVos.push({
                organizationName: m.orgName,
                organizationCode: m.orgCode,
                orgType: m.orgType,
                orgName: m.orgName,
                orgCode: m.orgCode,
              });
            });
            this.setValue({
              orgVos: orgVos || [],
              orgName: orgVos || [],
            });
          },
        };
      }
      return item;
    },
    // 渲染完成后执行
    formComplete() {
      const { type, id } = this.formConfig;
      console.log(this.formConfig, 'formConfig');

      if (type === 'add') {
        this.hiddenFields(true, ['productCode']);
      }
      if (type === 'edit' || type === 'view') {
        console.log('🚀 ~ formComplete ~ this.formConfig.row:', id);
        request.get('/cps/v1/fxhProductScoreRule/findById', { id }).then((res) => {
          if (res.success) {
            const { result } = res;
            this.setValue({
              ...result,
              orgName: result.orgList.map((item) => ({ ...item, organizationName: item.orgName })),
              productRegion: result.productRegion,
              productScoreRuleCode: result.productScoreRuleName,
              addForm: {
                subjectList: result.productList.map((item) => ({
                  ...item,
                  productIsEnable: item.productRange === 'level',
                  productLevelIsEnable: item.productRange !== 'level',
                  productName: item.productRange === 'level' ? '' : item.productName,
                  productCode: item.productRange === 'level' ? '' : item.productCode,
                  productLevelName: item.productRange !== 'level' ? '' : item.productLevelName,
                  productLevelCode: item.productRange !== 'level' ? '' : item.productLevelCode,
                  productLevelName1: item.productRange !== 'level' ? item.productLevelName : '',
                  productLevelCode1: item.productRange !== 'level' ? item.productLevelCode : '',
                })),
                subjectList1: result.excludeProductList.map((item) => ({ ...item, materialName: item.productName, materialCode: item.productCode })),
              },
              scoreRatio: result.gradingFactorsList,
            });
          }
        });
      }
      if (type === 'view') {
        this.getRule('addForm').props.disabled = true;
        this.getRule('BusinessOrganization').props.disabled = true;
        this.getRule('scoreRatio').props.disabled = true;

        // this.buttons.submit = false;
      } else {
        this.getRule('addForm').props.disabled = false;
        this.getRule('BusinessOrganization').props.disabled = false;
        this.getRule('scoreRatio').props.disabled = false;

        // this.buttons.submit = true;
      }
    },

    // 提交
    submit() {
      const formData = this.getFormData(); // 获取表单的值
      console.log('🚀 ~ submit ~ formData:', formData);
      if (formData) {
        const inputData = {};
        inputData.orgList = formData.orgName;
        inputData.productRegion = formData.productRegion;
        inputData.productScoreRuleName = formData.productScoreRuleCode;
        inputData.productList = formData.addForm.subjectList;
        inputData.gradingFactorsList = formData.scoreRatio;
        inputData.excludeProductList = formData.addForm.subjectList1.map((item) => ({ ...item, productName: item.materialName, productCode: item.materialCode }));

        inputData.productList.forEach((item) => {
          if (item.productRange === 'level') {
            item.productCode = item.productLevelCode;
            item.productName = item.productLevelName;
          } else {
            item.productLevelName = item.productLevelName1;
            item.productLevelCode = item.productLevelCode1;
          }
          delete item.productLevelName1;
          delete item.productLevelCode1;
        });
        console.log('🚀 ~ submit ~ inputData:', inputData);

        // 默认新增
        let url = '/cps/v1/fxhProductScoreRule/save';
        // 有id  就是编辑
        if (this.formConfig.id) {
          inputData.id = this.formConfig.id;
          url = '/cps/v1/fxhProductScoreRule/update';
        }
        request.post(url, inputData).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '操作成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
      }
    },
  },
};
