<template>
  <div class="custom-table">
    <vxe-table border show-overflow ref="xTable" :data="data">
      <vxe-table-column width="120" align="center" title="序号">
        <template v-slot="{ rowIndex }">
          {{ rowIndex + 1 }}
        </template>
      </vxe-table-column>
      <vxe-table-column
        field="productLevelName"
        align="center"
        title="产品系列"
      >
      </vxe-table-column>
      <vxe-table-column field="materialName" align="center" title="产品名称">
      </vxe-table-column>
      <vxe-table-column
        v-if="!disabled"
        field="operate-btn"
        align="center"
        width="60"
        title="操作"
      >
        <template v-slot="{ rowIndex }">
          <div class="setting-btn">
            <Popconfirm
              title="确定删除该数据？"
              confirm-button-type="text"
              @confirm="deleteItem(rowIndex)"
            >
              <el-button slot="reference" type="text">
                <i class="el-icon-delete" style="color: #f5222d"></i>
              </el-button>
            </Popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>
</template>

<script>
import { Popconfirm } from 'element-ui';

export default {
  props: {
    data: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Popconfirm,
  },
  data() {
    return {
      tableData: [],
    };
  },
  watch: {
    data(newVal, oldVal) {
      console.log('newVal', newVal);

      const tempData = newVal;
      tempData.forEach((item, index) => {
        item.ext9 = item.productLevelCode || item.ext9;
        item.ext10 = item.productLevelName || item.ext10;
      });
      this.tableData = tempData;
    },
  },
  methods: {
    deleteItem(rowIndex) {
      this.$emit('deleteItem', rowIndex);
    },
  },
};
</script>

<style></style>
