<script>
import Modal from '@/found/components/modal';
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Form from '../form';

export default {
  extends: TablePage,
  name: 'product_score_rules',
  components: {
    Modal,
    Form,
  },
  data() {
    return {
      formConfig: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
    };
  },
  created() {
    this.getConfigList('product_score_rules');
  },
  methods: {
    beforeSearchEvent(val) {
      if (val.$event.type === 'reset') {
        this.$emit('reset');
      }
      return true;
    },
    // 按钮点击事件
    modalClick({ val, row }) {
      this.formConfig = {};
      this.formName = 'Form';

      this.modalConfig.width = '80%';
      this.modalConfig.height = '600px';
      this.formConfig = {
        type: val.code,
        id: null,
      };
      if (val.code === 'add') {
        this.modalConfig.title = '新增';
        this.openFull();
      } else if (val.code === 'edit') {
        console.log(val, 'row.id');
        this.formConfig = {
          type: val.code,
          id: row.id,
          row: JSON.parse(JSON.stringify(row)),
          code: val.code,
        };
        this.modalConfig.title = '编辑';
        this.openFull();
      } else if (val.code === 'view') {
        this.formConfig = {
          type: val.code,
          id: row.id,
          row: JSON.parse(JSON.stringify(row)),
          code: val.code,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },

    // disableClick(urls, text) {
    //   const datas = [];
    //   this.selectRow.forEach((v) => {
    //     datas.push(v.id);
    //   });
    //   request.post(urls, datas).then((res) => {
    //     if (res.success) {
    //       this.$message({
    //         type: 'success',
    //         message: `${text}成功`,
    //       });
    //       this.getList();
    //     }
    //   });
    // },
  },
};
</script>
