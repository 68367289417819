var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "title" }, [_vm._v("排除产品")]),
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _c(
        "el-button",
        {
          attrs: { type: "primary", icon: "el-icon-plus" },
          on: { click: _vm.openModal },
        },
        [_vm._v("添加系列")]
      ),
      _c("TableList", {
        staticStyle: { "margin-top": "10px" },
        attrs: { disabled: _vm.disabled, data: _vm.datalist },
        on: {
          deleteItem: function (rowIndex) {
            return _vm.deleteItem(rowIndex)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }