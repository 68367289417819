<template>
  <div class="edit-table">
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <vxe-button @click="addTable()">添加行</vxe-button>
      </template>
    </vxe-toolbar>
    <vxe-table
      show-overflow=""
      ref="fineTable"
      :data="subjectList"
      height="300px"
    >
      <vxe-table-column min-width="100" field="rowIndex" title="序号">
        <template v-slot="{ rowIndex }">
          {{ rowIndex + 1 }}
        </template>
      </vxe-table-column>
      <vxe-table-column
        min-width="100"
        field="gradingFactorsCode"
        title="得分因子"
      >
        <template #default="{ row, rowIndex }">
          <el-select
            v-model="row.gradingFactorsCode"
            @change="gradingFactorsCodeChange($event, rowIndex)"
            placeholder="请选择得分因子"
            :disabled="disabled"
          >
            <el-option
              v-for="item in gradingFactiors"
              :key="item.gradingFactorsCode"
              :label="item.gradingFactorsName"
              :value="item.gradingFactorsCode"
            >
            </el-option>
          </el-select>
        </template>
      </vxe-table-column>
      <vxe-table-column min-width="100" field="ratio" title="得分比例（%）">
        <template #default="{ row, rowIndex }">
          <el-input
            v-model.number="row.ratio"
            type="number"
            @input="handleInput($event, rowIndex)"
            placeholder="请输入"
            :disabled="disabled"
          ></el-input>
        </template>
      </vxe-table-column>
      <!-- <vxe-table-column min-width="100" field="canUseAmount" title="可用余额"></vxe-table-column> -->
      <vxe-column title="操作" width="80" v-if="!disabled">
        <template #default="{ row, rowIndex }">
          <template>
            <span
              @click="deletRow(row, rowIndex)"
              style="color: red; cursor: pointer"
              >删除</span
            >
          </template>
        </template>
      </vxe-column>
    </vxe-table>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'scoreRatio',
  components: {
    SelectConfig,
  },
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    value: {
      handler(val, val1) {
        this.subjectList = val;
      },
      deep: true,
    },
  },
  data() {
    return {
      subjectList: [], // 当前关联费用科目数据
      listData: {}, // 预算列表数据
      gradingFactiors: [], // 得分因子
    };
  },
  async created() {
    await this.getDictSelect();
  },
  mounted() {
    // if (this.saveRef) {
    //   this.saveRef(this);
    // }
  },
  methods: {
    handleInput(e, index) {
      const regex = /^\d{0,3}(\.\d{0,2})?$/;
      console.log('🚀 ~ handleInput ~ regex.test(e):', regex.test(e));
      if (!regex.test(e)) {
        this.subjectList[index].ratio = '';
      }
    },
    getDictSelect() {
      request
        .post('/cps/v1/fxh/gradingFactiors/findByConditions')
        .then((res) => {
          if (res.success) {
            this.gradingFactiors = res.result.data || [];
          }
        });
    },
    // 删除行
    deletRow(row, rowIndex) {
      console.log(this.subjectList);
      this.$confirm('您确定要删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$refs.fineTable.remove(row);
        this.subjectList.splice(rowIndex, 1);
        this.$emit('change', this.subjectList);
        this.$message({ type: 'success', message: '删除成功!' });
      });
    },

    // 获取预算列表
    async getBudgetList(n) {
      const res = await request.post(
        '/tpm/tpmFeeBudgetControlController/list',
        {
          actType: 'promotion',
          // pageNum: 1,
          // pageSize: 500,
          actBeginDate: n.startTime,
          actEndDate: n.endTime,
        },
      );
      return res.result.data || [];
    },

    // 新增预算信息
    addTable() {
      this.subjectList.push({
        gradingFactorsCode: '',
        ratio: '',
      });

      this.$emit('input', this.subjectList);
    },

    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    onGetSelect(val) {
      const data = this.getReset(val, [], 'id');
      this.listData.data = data;
      this.subjectList = data;
      this.$emit('change', data);
    },

    // 打开点击弹窗
    openHandleClick() {
      const params = {
        data: [],
        selectionList: [],
        functionCode: 'prolist',
        idKey: 'productCode',
        noReset: true,
        paramData: {
          enableStatus: '009',
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 删除
    clearFn(e, code, index, aIndex) {
      e.stopPropagation();
    },
    gradingFactorsCodeChange(val, index) {
      const res = this.gradingFactiors.find(
        (item) => item.gradingFactorsCode === val,
      );
      this.subjectList[index].gradingFactorsName = res.gradingFactorsName;
    },
  },
};
</script>

<style lang="less" scoped>
.edit-table {
  padding-left: 50px;
  margin-left: 0 !important;
}
.cost-select {
  position: relative;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
</style>
