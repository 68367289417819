<template>
  <div class="edit-table">
    <div class="padding_50">
      <vxe-toolbar v-if="!disabled">
        <template v-slot:buttons>
          <vxe-button @click="addTable()">添加行</vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table
        show-overflow=""
        ref="fineTable"
        :data="subjectList"
        height="300px"
      >
        <vxe-table-column width="50" field="rowIndex" title="序号">
          <template v-slot="{ rowIndex }">
            {{ rowIndex + 1 }}
          </template>
        </vxe-table-column>
        <vxe-table-column width="100" field="productRange" title="产品维度">
          <template #default="{ row, rowIndex }">
            <el-select
              v-model="row.productRange"
              placeholder="请选择"
              @change="handleSelect(row, rowIndex)"
              :disabled="disabled"
            >
              <el-option
                v-for="item in productRange"
                :key="item.dictCode"
                :label="item.dictValue"
                :value="item.dictCode"
              >
              </el-option>
            </el-select>
          </template>
        </vxe-table-column>
        <vxe-table-column
          min-width="200"
          field="productLevelName"
          title="产品系列"
        >
          <template #default="{ row, rowIndex }">
            <div class="cost-select">
              <el-select
                filterable
                :filter-method="getProLevelList"
                @visible-change="getProLevelList('')"
                v-model="row.productLevelCode"
                @change="productLevelNameChange($event, rowIndex)"
                clearable
                :disabled="row.productLevelIsEnable || disabled"
              >
                <el-option
                  v-for="item in proLevelList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </template>
        </vxe-table-column>
        <vxe-table-column min-width="200" field="productName" title="产品名称">
          <template #default="{ row, rowIndex }">
            <el-select
              filterable
              :filter-method="getRoleSelectListArr"
              @visible-change="getRoleSelectListArr('')"
              v-model="row.productCode"
              clearable
              :disabled="row.productIsEnable || disabled"
              @change="productNameChange($event, rowIndex)"
            >
              <el-option
                v-for="item in proList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </vxe-table-column>
        <vxe-table-column width="100" field="score" title="产品凤香值/瓶">
          <template #default="{ row, rowIndex }">
            <el-input
              v-model="row.score"
              type="number"
              @input="scoreInput($event, rowIndex)"
              placeholder="请输入"
              :disabled="disabled"
            ></el-input>
          </template>
        </vxe-table-column>
        <vxe-table-column width="100" field="scanRequired" title="扫码次数">
          <template #default="{ row, rowIndex }">
            <el-input
              v-model="row.scanRequired"
              type="number"
              @input="handleInput($event, rowIndex, 0)"
              placeholder="请输入"
              :disabled="disabled"
            ></el-input>
          </template>
        </vxe-table-column>
        <vxe-table-column width="100" field="rewardGranted" title="获奖次数">
          <template #default="{ row, rowIndex }">
            <el-input
              v-model="row.rewardGranted"
              type="number"
              @input="handleInput($event, rowIndex, 1)"
              placeholder="请输入"
              :disabled="disabled"
            ></el-input>
          </template>
        </vxe-table-column>
        <!-- <vxe-table-column min-width="100" field="canUseAmount" title="可用余额"></vxe-table-column> -->
        <vxe-column title="操作" width="80" v-if="!disabled">
          <template #default="{ row, rowIndex }">
            <template>
              <span
                @click="deletRow(row, rowIndex)"
                style="color: red; cursor: pointer"
                >删除</span
              >
            </template>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="form_title">排除产品</div>
    <div class="padding_50">
      <vxe-toolbar v-if="!disabled">
        <template v-slot:buttons>
          <vxe-button @click="addTable1()">添加行</vxe-button>
        </template>
      </vxe-toolbar>
      <vxe-table
        show-overflow=""
        ref="fineTable"
        :data="subjectList1"
        height="300px"
      >
        <vxe-table-column min-width="100" field="rowIndex" title="序号">
          <template v-slot="{ rowIndex }">
            {{ rowIndex + 1 }}
          </template>
        </vxe-table-column>
        <!-- <vxe-table-column
        min-width="100"
        field="productLevelName"
        title="产品系列"
      >
      </vxe-table-column> -->
        <vxe-table-column min-width="100" field="materialName" title="产品名称">
        </vxe-table-column>
        <!-- <vxe-table-column min-width="100" field="canUseAmount" title="可用余额"></vxe-table-column> -->
        <vxe-column title="操作" width="80" v-if="!disabled">
          <template #default="{ row }">
            <template>
              <span @click="deletRow1(row)" style="color: red; cursor: pointer"
                >删除</span
              >
            </template>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <!-- 弹框 -->
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
  </div>
</template>

<script>
import request from '@/found/utils/request';
import SelectConfig from '@/found/components/select_org_cus_ter/select_config.vue';

export default {
  name: 'addForm',
  components: {
    SelectConfig,
  },
  props: {
    value: Object,
    disabled: {
      type: Boolean,
      default: () => false,
    },
  },
  watch: {
    value: {
      handler(val, val1) {
        console.log('🚀 ~ handler ~ val:', val);
        this.subjectList = val.subjectList || [];
        this.subjectList1 = val.subjectList1 || [];
      },
      deep: true,
    },
  },
  data() {
    return {
      subjectList: [], // 当前关联费用科目数据
      listData: {}, // 预算列表数据
      productRange: [], // 产品维度
      productCode: [], // 产品系列
      rowDataIndex: 0, // 行索引
      proList: [], // 产品名称下拉列表
      proLevelList: [], // 产品系列下拉列表
      subjectList1: [], // 排除产品数据
    };
  },
  async created() {
    await this.getDictSelect();
    this.getRoleSelectListArr('');
    this.getProLevelList('');
  },
  mounted() {
    // if (this.saveRef) {
    //   this.saveRef(this);
    // }
  },
  methods: {
    handleInput(e, index, type) {
      console.log('🚀 ~ handleInput ~ e, index, type:', e, index, type);
      if (type) {
        this.subjectList[index].rewardGranted = e.replace(/\D/g, '');
      } else {
        this.subjectList[index].scanRequired = e.replace(/\D/g, '');
      }
    },
    scoreInput(e, index) {
      const regex = /^\d{0,5}(\.\d{0,2})?$/;
      if (!regex.test(e)) {
        this.subjectList[index].score = '';
      }
    },
    getDictSelect() {
      const params = ['cps_product_range', 'cps_product_code'];
      request.post('/mdm/mdmdictdata/batchDictSelect', params).then((res) => {
        if (res.success) {
          this.productRange = res.result.cps_product_range || [];
          this.productCode = res.result.cps_product_code || [];
        }
      });
    },
    // 删除行
    deletRow(row, rowIndex) {
      console.log(this.subjectList);
      this.$confirm('您确定要删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        console.log(this.subjectList, 'subjectList');
        console.log(this.subjectList1, 'subjectList1');

        this.$refs.fineTable.remove(row);
        this.subjectList.splice(rowIndex, 1);
        this.subjectList1 = this.subjectList1.filter(
          (val) => val.productLevelCode !== row.productLevelCode,
        );
        this.$emit('input', {
          subjectList: this.subjectList,
          subjectList1: this.subjectList1,
        });

        this.$message({ type: 'success', message: '删除成功!' });
      });
    },
    // 删除行
    deletRow1(row) {
      console.log(this.subjectList);
      this.$confirm('您确定要删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        this.$refs.fineTable.remove(row);
        this.subjectList1.forEach((item, index) => {
          if (item.id === row.id) {
            this.subjectList1.splice(index, 1);
          }
        });
        this.$emit('input', {
          subjectList: this.subjectList,
          subjectList1: this.subjectList1,
        });

        this.$message({ type: 'success', message: '删除成功!' });
      });
    },

    // 获取预算列表
    async getBudgetList(n) {
      const res = await request.post(
        '/tpm/tpmFeeBudgetControlController/list',
        {
          actType: 'promotion',
          // pageNum: 1,
          // pageSize: 500,
          actBeginDate: n.startTime,
          actEndDate: n.endTime,
        },
      );
      return res.result.data || [];
    },

    // 新增预算信息
    addTable() {
      this.subjectList.push({
        productRange: '',
        productCode: '',
        productName: '',
        score: '',
        scanRequired: '',
        rewardGranted: '',
        productLevelIsEnable: true,
        productIsEnable: true,
      });
      this.$emit('input', {
        subjectList: this.subjectList,
        subjectList1: this.subjectList1,
      });
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    onGetSelect(val) {
      const data = this.getReset(val, [], 'id');
      this.subjectList1 = this.getReset(
        this.subjectList1.concat(data),
        [],
        'id',
      );
      this.$emit('input', {
        subjectList: this.subjectList,
        subjectList1: this.subjectList1,
      });
    },

    // 打开点击弹窗产品名称
    productNameClick(index) {
      this.rowDataIndex = index;
      const params = {
        data: [],
        selectionList: [],
        functionCode: 'prolist',
        idKey: 'productCode',
        noReset: true,
        paramData: {
          enableStatus: '009',
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    // 打开点击弹窗产品系列
    productLevelNameClick() {
      const params = {
        data: [],
        selectionList: [],
        functionCode: 'select_level_list',
        idKey: 'productLevelName',
        noReset: true,
        paramData: {
          enableStatus: '009',
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    handleSelect(row, index) {
      if (row.productRange === 'product') {
        this.$set(this.subjectList[index], 'productIsEnable', false);
        this.$set(this.subjectList[index], 'productLevelIsEnable', true);
      } else {
        this.$set(this.subjectList[index], 'productIsEnable', true);
        this.$set(this.subjectList[index], 'productLevelIsEnable', false);
      }
    },
    // 删除
    clearFn(e, code, index, aIndex) {
      e.stopPropagation();
    },
    // 获取产品名称下拉
    getRoleSelectListArr(name) {
      console.log(name, 'name');

      request
        .post(
          '/mdm/mdmProductController/commonPageList',
          {
            enableStatus: '009',
            productName: name,
            pageNum: 1,
            pageSize: 1000,
          },
          {},
        )
        .then((res) => {
          if (res.success) {
            this.proList = res.result.data.map((a) => ({
              label: a.productName,
              value: a.productCode,
              productLevelName: a.productLevelName,
              productLevelCode: a.productLevelCode,
            }));
          }
        });
    },
    // 获取产品系列下拉
    getProLevelList(name) {
      request
        .post(
          '/mdm/mdmProductLevelController/pageList',
          {
            enableStatus: '009',
            productLevelName: name,
            pageNum: 1,
            pageSize: 1000,
          },
          {},
        )
        .then((res) => {
          if (res.success) {
            this.proLevelList = res.result.data.map((a) => ({
              label: a.productLevelName,
              value: a.productLevelCode,
            }));
          }
        });
    },
    productLevelNameChange(val, index) {
      if (val === '') return;
      const res = this.proLevelList.find((item) => item.value === val);
      this.subjectList[index].productLevelName = res.label;
    },
    // 新增预算信息
    addTable1() {
      const addForm = this.subjectList;
      console.log('addForm', addForm);
      const params = {
        data: [],
        selectionList: [],
        functionCode: 'exclude_product_table',
        idKey: 'productCode',
        noReset: true,
        paramData: {
          enableStatus: '009',
          productLevelCodeList: addForm.map((item) => item.productLevelCode),
        },
      };
      this.$refs.selectConfig.openSelectModal(params);
    },
    productNameChange(val, index) {
      if (val === '') return;
      const res = this.proList.find((item) => item.value === val);
      this.subjectList[index].productName = res.label;
      this.subjectList[index].productLevelCode1 = res.productLevelCode;
      this.subjectList[index].productLevelName1 = res.productLevelName;
    },
  },
};
</script>

<style lang="less" scoped>
.padding_50 {
  padding-left: 50px;
}
.form_title {
  font-size: 16px;
  height: 20px;
  line-height: 20px;
  font-weight: 600;
  margin-left: 0;
  margin-top: 20px;
  margin-bottom: 35px;
}
.edit-table {
  // padding-left: 50px;
  margin-left: 0 !important;
}
.cost-select {
  position: relative;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
</style>
