<template>
  <div>
    <div class="title">排除产品</div>
    <SelectConfig ref="selectConfig" @onGetSelect="onGetSelect" />
    <el-button type="primary" icon="el-icon-plus" @click="openModal"
      >添加系列</el-button
    >
    <TableList
      :disabled="disabled"
      style="margin-top: 10px"
      :data="datalist"
      @deleteItem="(rowIndex) => deleteItem(rowIndex)"
    />
  </div>
</template>
<script>
import SelectConfig from '@/found/components/select_org_cus_ter/select_config';
import TableList from './table/table.vue';

export default {
  name: 'ExcludeProductList',
  components: {
    SelectConfig,
    TableList,
  },
  props: {
    value: Array,
    catena: [Array, Object, String],
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      datalist: [],
      dialogVisible: false,
    };
  },
  watch: {
    value: {
      handler(val, val1) {
        console.log('jianting value', val, val1);
      },
      deep: true,
    },
  },

  methods: {
    openModal() {
      if (!this.catena) {
        this.$message.error('请先选择系列');
        return;
      }
      this.selectKeys = this.datalist;
      const params = {
        functionCode: 'exclude_product_table',
        data: this.selectKeys,
        idKey: 'id',
        paramData: {
          code: this.catena,
        },
      };

      this.$refs.selectConfig.openSelectModal(params);
    },
    // 判断去重
    getReset(list1, list2, key) {
      const list = list2;
      if (list1 && list1.length) {
        list1.forEach((v1) => {
          let notRepeat = true;
          const rowData = v1;
          for (const v2 in list2) {
            if (v1[key] === list2[v2][key]) {
              notRepeat = true;
              return false;
            }
          }
          if (notRepeat) {
            list.push(rowData);
          }
          return rowData;
        });
      }
      return list;
    },
    onGetSelect(val) {
      let data = this.getReset(val, [], 'materialCode');
      data = data.map((v) => {
        const obj = v;
        obj.materialCode = obj.code || obj.materialCode;
        obj.materialName = obj.name || obj.materialName;
        return obj;
      });
      this.$set(this, 'datalist', data);
      console.log(this.datalist);
    },
    deleteItem(rowIndex) {
      // console.log('删除的所以', index, rowIndex);
      // const index = this.operateItemIndex;
      this.datalist.splice(rowIndex, 1);
      this.$emit('input', this.dataList);
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight: 600;
}
</style>
